import React, { useState } from "react";
import PropTypes from "prop-types"; // Importing PropTypes for type checking
import { TextField, Button, Avatar, Box, Typography, IconButton } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import CloseIcon from "@mui/icons-material/Close";

const Chatbox = ({ onClose }) => {
  const [messages, setMessages] = useState([
    {
      sender: "bot",
      text: "Welcome to GrowAITech! How can I assist you today?",
      timestamp: new Date().toLocaleTimeString(),
    },
  ]);
  const [userInput, setUserInput] = useState("");

  const responses = {
    hi: "Hi! How can I assist you today?",
    "about us":
      "At GrowAITech, our team of coding professionals is dedicated to providing cutting-edge IT solutions tailored to your business needs. We focus on Web Development, Artificial Intelligence, Data Analytics, Cybersecurity, and Creative Design.",
    hello: "Hello! How can I assist you today?",
    services:
      "We offer a wide range of IT services, including:\n- Web Development\n- Artificial Intelligence\n- Data Analytics\n- Cybersecurity\n- Creative Design\n- App Development.",
    internship:
      "We offer internships in the following fields:\n" +
      "- **Web Development**: Learn to build and maintain websites using HTML, CSS, JavaScript, and frameworks like React.\n" +
      "- **Artificial Intelligence**: Gain hands-on experience with machine learning algorithms and AI technologies.\n" +
      "- **Data Analytics**: Work on data collection, analysis, and visualization techniques.\n" +
      "- **Cybersecurity**: Learn about protecting systems and networks from cyber threats.\n" +
      "- **Creative Design**: Enhance your skills in graphic design, UI/UX, and multimedia projects.\n\n" +
      "### Duration:\n" +
      "Internships can last from **10 days to 6 months**, depending on the project and your availability.\n\n" +
      "### Application Process:\n" +
      "1. Submit your resume to growaitech@gmail.com.\n" +
      "2. Include a brief cover letter explaining your interest and availability.\n" +
      "3. Selected candidates will be contacted for an interview.\n\n" +
      "### Apply Now:\n" +
      "[Click here to apply for the internship](https://docs.google.com/forms/d/e/1FAIpQLSdFFW9HgKTa7BMvrT043ToY-YlEi5ES1IBl5T2K-TisqxcRuw/viewform?pli=1)\n\n" +
      "For any further inquiries, feel free to reach out!",
    workshop:
      "We offer various workshops designed to enhance your skills and knowledge in the following areas:\n" +
      "- **Web Development**: Hands-on sessions on HTML, CSS, JavaScript, and popular frameworks like React.\n" +
      "- **Data Science**: Learn about data analysis, machine learning algorithms, and visualization tools.\n" +
      "- **Digital Marketing**: Understand SEO, social media marketing, and content creation strategies.\n" +
      "- **UI/UX Design**: Explore design principles, user research, and prototyping techniques.\n" +
      "- **Mobile App Development**: Build your own mobile applications for Android and iOS.\n\n" +
      "### Duration:\n" +
      "Workshops typically last from **2 days to 2 weeks**, depending on the topic and format.\n\n" +
      "### Application Process:\n" +
      "1. Register through our website.\n" +
      "2. Pay the workshop fee to secure your spot.\n" +
      "3. You'll receive a confirmation email with further details.\n\n" +
      "### Apply Now:\n" +
      "[Click here to apply for the workshop](https://docs.google.com/forms/d/e/1FAIpQLSe6Zf-tGgAXpxoRKVc8aJuGkjeAaX8sTFy-wBThx5VNYjgzbQ/viewform)\n\n" +
      "For inquiries, feel free to reach out to us!",
    contact:
      "You can reach us at:\nEmail: growaitech@gmail.com\nPhone: +91 6374549599, +91 733 921 7119.\nAddress: Door No. 61-4, S.F. No: 25/12 A 2B,\nSalem Bangalore Byepass Road,\nService Rd, Nedunchalai Nagar,\nSalem, Tamil Nadu 636005.",
    help:
      "I'm here to help! You can ask about our services, internship opportunities, workshops, or how to contact us.",
    default:
      "I'm sorry, I didn't quite understand that. You can ask about:\n- Internship\n- Workshop\n- Contact\n- Services",
  };

  const sendMessage = () => {
    if (userInput.trim()) {
      const newMessage = {
        sender: "user",
        text: userInput,
        timestamp: new Date().toLocaleTimeString(),
      };
      setMessages((prevMessages) => [...prevMessages, newMessage]);
      setUserInput("");

      const userMessageLower = userInput.toLowerCase();
      const botResponseText = getBotResponse(userMessageLower);

      const botResponse = {
        sender: "bot",
        text: botResponseText.text,
        timestamp: new Date().toLocaleTimeString(),
        buttons: botResponseText.buttons || [], // Include buttons if available
      };
      setTimeout(() => {
        setMessages((prevMessages) => [...prevMessages, botResponse]);
      }, 500);
    }
  };

  const getBotResponse = (userMessage) => {
    if (userMessage.includes("hello")) return { text: responses.hello };
    if (userMessage.includes("about us")) return { text: responses["about us"] };
    if (userMessage.includes("services")) return { text: responses.services };
    if (userMessage.includes("internship")) return { text: responses.internship };
    if (userMessage.includes("workshop")) return { text: responses.workshop };
    if (userMessage.includes("contact")) return { text: responses.contact };
    if (userMessage.includes("help")) return { text: responses.help };
    return {
      text: responses.default,
      buttons: [
        { label: "Internship", value: "internship" },
        { label: "Workshop", value: "workshop" },
        { label: "Contact", value: "contact" },
        { label: "Services", value: "services" },
      ],
    };
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      sendMessage();
    }
  };

  const handleButtonClick = (buttonValue) => {
    const botResponse = {
      sender: "bot",
      text: responses[buttonValue],
      timestamp: new Date().toLocaleTimeString(),
    };
    setMessages((prevMessages) => [...prevMessages, botResponse]);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        zIndex: 1000,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: { xs: "400px", sm: "600px" },
          width: "100%",
          maxWidth: "400px",
          backgroundColor: "background.paper",
          borderRadius: 2,
          boxShadow: 3,
          position: "relative",
        }}
      >
        {/* Chat Header */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "16px",
            borderBottom: "1px solid",
            borderColor: "divider",
            backgroundColor: "primary.main",
            color: "white",
          }}
        >
          <Typography variant="h6">GAT BOT</Typography>
          <IconButton onClick={onClose} sx={{ color: "secondary.main" }}>
            <CloseIcon />
          </IconButton>
        </Box>

        {/* Chat Body */}
        <Box
          sx={{
            flex: 1,
            overflowY: "auto",
            padding: "8px",
            backgroundColor: "background.default",
          }}
        >
          {messages.map((message, index) => (
            <Box
              key={index}
              display="flex"
              alignItems="center"
              justifyContent={message.sender === "bot" ? "flex-start" : "flex-end"}
              mb={1}
            >
              {message.sender === "bot" && <Avatar alt="Bot" sx={{ mr: 1 }} />}
              <Box
                sx={{
                  maxWidth: "70%",
                  backgroundColor: message.sender === "bot" ? "grey.300" : "primary.main",
                  color: message.sender === "bot" ? "black" : "white",
                  borderRadius: 2,
                  padding: "8px",
                  wordWrap: "break-word",
                }}
              >
                <Typography variant="body1">{message.text}</Typography>
                {message.buttons && message.buttons.length > 0 && (
                  <Box mt={1}>
                    {message.buttons.map((button, idx) => (
                      <Button
                        key={idx}
                        variant="outlined"
                        onClick={() => handleButtonClick(button.value)}
                        sx={{ marginRight: 1 }}
                      >
                        {button.label}
                      </Button>
                    ))}
                  </Box>
                )}
              </Box>
              {message.sender === "user" && <Avatar alt="User" sx={{ ml: 1 }} />}
            </Box>
          ))}
        </Box>

        {/* Chat Input */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            padding: "8px",
            borderTop: "1px solid",
            borderColor: "divider",
          }}
        >
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            placeholder="Type a message..."
            value={userInput}
            onChange={(e) => setUserInput(e.target.value)}
            onKeyPress={handleKeyPress}
          />
          <IconButton onClick={sendMessage} sx={{ ml: 1 }}>
            <SendIcon />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

Chatbox.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default Chatbox;
