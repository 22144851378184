import React, { useState, useEffect } from 'react';
import {
    AppBar,
    Toolbar,
    Typography,
    Button,
    IconButton,
    Drawer,
    List,
    ListItem,
    ListItemText,
    Menu,
    useMediaQuery,
    Divider,
} from '@mui/material';
import { Link as ScrollLink } from 'react-scroll';
import { useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import GitHubIcon from '@mui/icons-material/GitHub';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';

const Header = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [scrolled, setScrolled] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [activeSection, setActiveSection] = useState('home');

    useEffect(() => {
        const handleScroll = () => {
            setScrolled(window.scrollY > 50);
            const sections = ['home', 'about', 'services', 'contact'];
            const scrollY = window.scrollY;

            sections.forEach((section) => {
                const sectionElement = document.getElementById(section);
                if (sectionElement) {
                    const { offsetTop, clientHeight } = sectionElement;
                    if (scrollY >= offsetTop - 70 && scrollY < offsetTop + clientHeight) {
                        setActiveSection(section);
                    }
                }
            });
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleSectionChange = (section) => {
        setActiveSection(section);
    };

    return (
        <>
            <AppBar
                position="fixed"
                className={`navbar ${scrolled ? 'scrolled' : ''}`}
                sx={{ backgroundColor: '#ebf2ea', boxShadow: 'none' }}
            >
                <Toolbar>
                    {/* Wrap logo in ScrollLink to navigate to home */}
                    <ScrollLink to="home" smooth={true} duration={500} offset={-70}>
                        <img
                            src="/logo.png" // Logo from public folder
                            alt="GrowAITech Logo"
                            style={{
                                width: '40px',
                                height: '40px',
                                marginRight: '4px',
                                cursor: 'pointer', // Change cursor to pointer
                            }}
                        />
                    </ScrollLink>

                    <Typography
                        variant="h6"
                        sx={{
                            flexGrow: 1,
                            fontFamily: 'Lato',
                            fontSize: '24px',
                            fontWeight: 'bold',
                            cursor: 'pointer',
                        }}
                        onClick={() => handleSectionChange('home')}
                    >
                        <ScrollLink to="home" smooth={true} duration={500} offset={-70}>
                            <span style={{ color: theme.palette.primary.main }}>G</span>
                            <span style={{ color: theme.palette.secondary.main }}>row</span>
                            <span style={{ color: theme.palette.primary.main }}>A</span>
                            <span style={{ color: theme.palette.secondary.main }}>I</span>
                            <span style={{ color: theme.palette.primary.main }}>T</span>
                            <span style={{ color: theme.palette.secondary.main }}>ech</span>
                        </ScrollLink>
                    </Typography>

                    {!isMobile && (
                        <div className="nav-buttons">
                            {['home', 'about', 'services', 'contact'].map((text) => (
                                <ScrollLink
                                    key={text}
                                    to={text}
                                    smooth={true}
                                    duration={500}
                                    offset={-70}
                                    onClick={() => {
                                        handleSectionChange(text);
                                    }}
                                >
                                    <Button
                                        className="animated-button"
                                        sx={{
                                            color: activeSection === text ? theme.palette.primary.main : theme.palette.secondary.main,
                                            fontFamily: 'Lato',
                                            fontSize: '24px',
                                            fontWeight: 'bold',
                                            textTransform: 'none',
                                        }}
                                    >
                                        {text.charAt(0).toUpperCase() + text.slice(1)}
                                    </Button>
                                </ScrollLink>
                            ))}
                            <IconButton
                                onClick={(event) => setMenuAnchorEl(event.currentTarget)}
                                color="inherit"
                                sx={{ color: theme.palette.primary.main }} // Primary color for the menu icon
                            >
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                anchorEl={menuAnchorEl}
                                open={Boolean(menuAnchorEl)}
                                onClose={() => setMenuAnchorEl(null)}
                                sx={{ mt: 1, '& .MuiPaper-root': { width: '180px', padding: '5px' } }}
                            >
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px' }}>
                                    <IconButton href="https://www.linkedin.com/company/growaitech" target="_blank" sx={socialButtonStyles}>
                                        <LinkedInIcon />
                                        <Typography variant="body2" sx={{ marginLeft: 2, fontWeight: 'bold' }}>LinkedIn</Typography>
                                    </IconButton>
                                    <IconButton href="https://x.com/GrowAiTech" target="_blank" sx={socialButtonStyles}>
                                        <TwitterIcon />
                                        <Typography variant="body2" sx={{ marginLeft: 2, fontWeight: 'bold' }}>Twitter</Typography>
                                    </IconButton>
                                    <IconButton href="https://github.com/growaitech" target="_blank" sx={socialButtonStyles}>
                                        <GitHubIcon />
                                        <Typography variant="body2" sx={{ marginLeft: 2, fontWeight: 'bold' }}>GitHub</Typography>
                                    </IconButton>
                                    <IconButton href="https://www.instagram.com/growaitech" target="_blank" sx={socialButtonStyles}>
                                        <InstagramIcon />
                                        <Typography variant="body2" sx={{ marginLeft: 2, fontWeight: 'bold' }}>Instagram</Typography>
                                    </IconButton>
                                    <IconButton href="https://www.facebook.com" target="_blank" sx={socialButtonStyles}>
                                        <FacebookIcon />
                                        <Typography variant="body2" sx={{ marginLeft: 2, fontWeight: 'bold' }}>Facebook</Typography>
                                    </IconButton>
                                </div>
                            </Menu>
                        </div>
                    )}

                    {isMobile && (
                        <>
                            <IconButton onClick={() => setDrawerOpen(true)} color="inherit" sx={{ color: theme.palette.primary.main }}>
                                <MenuIcon />
                            </IconButton>
                            <Drawer anchor="right" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
                                <div role="presentation" onClick={() => setDrawerOpen(false)} onKeyDown={() => setDrawerOpen(false)}>
                                    <List>
                                        {['home', 'about', 'services', 'contact'].map((text) => (
                                            <ListItem button key={text}>
                                                <ScrollLink
                                                    to={text}
                                                    smooth={true}
                                                    duration={500}
                                                    offset={-70}
                                                    onClick={() => {
                                                        handleSectionChange(text);
                                                        setDrawerOpen(false);
                                                    }}
                                                >
                                                    <ListItemText primary={text.charAt(0).toUpperCase() + text.slice(1)} />
                                                </ScrollLink>
                                            </ListItem>
                                        ))}
                                    </List>
                                    <Divider />
                                    <List>
                                        {[{
                                            href: 'https://www.linkedin.com/company/growaitech', icon: <LinkedInIcon />, label: 'LinkedIn'
                                        }, {
                                            href: 'https://x.com/GrowAiTech', icon: <TwitterIcon />, label: 'Twitter'
                                        }, {
                                            href: 'https://github.com/growaitech', icon: <GitHubIcon />, label: 'GitHub'
                                        }, {
                                            href: 'https://www.instagram.com/growaitech', icon: <InstagramIcon />, label: 'Instagram'
                                        }, {
                                            href: 'https://www.facebook.com', icon: <FacebookIcon />, label: 'Facebook'
                                        }].map(({ href, icon, label }) => (
                                            <ListItem button key={label} component="a" href={href} target="_blank" sx={socialButtonStyles}>
                                                <IconButton>{icon}</IconButton>
                                                <ListItemText primary={label} sx={{ marginLeft: 2 }} />
                                            </ListItem>
                                        ))}
                                    </List>
                                </div>
                            </Drawer>
                        </>
                    )}
                </Toolbar>
            </AppBar>
        </>
    );
};

const socialButtonStyles = {
    color: theme => theme.palette.secondary.main,
    '&:hover': {
        color: theme => theme.palette.primary.main,
        backgroundColor: 'white',
    },
    width: '100%',
    justifyContent: 'flex-start',
    padding: '8px 16px',
};

export default Header;
