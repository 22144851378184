import React, { useState } from 'react';
import { Box, Typography, Button, TextField, Grid, IconButton, Snackbar } from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import TwitterIcon from '@mui/icons-material/Twitter';
import GitHubIcon from '@mui/icons-material/GitHub';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import CheckCircleIcon from '@mui/icons-material/CheckCircle'; // Import check circle icon
import { useForm } from 'react-hook-form';

function Contact() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  // State for Snackbar
  const [openSnackbar, setOpenSnackbar] = useState(false);

  // Function to handle form submission
  const onSubmit = (data) => {
    // Show Snackbar
    setOpenSnackbar(true);
    reset(); // Reset form after successful submission
  };

  // Function to handle Snackbar close
  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <Box
      sx={{
        position: 'relative',
        width: '90%',
        maxWidth: '1196px',
        minHeight: '60vh',
        background: '#FFFFFF',
        boxShadow: '0px 0px 60px 30px rgba(0, 0, 0, 0.03)',
        borderRadius: '10px',
        padding: { xs: '20px', md: '40px' },
        overflow: 'hidden',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Grid container spacing={2}>
        {/* First Column */}
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              backgroundColor: '#034522',
              color: '#FFFFFF',
              padding: '20px',
              borderRadius: '10px',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              position: 'relative',
            }}
          >
            <Typography
              variant="h4"
              sx={{
                fontWeight: 600,
                fontSize: { xs: '1.5rem', md: '2rem' },
                textAlign: 'center',
              }}
            >
              You can reach us at:
            </Typography>
            <Box sx={{ marginTop: '15px' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                <MailOutlineIcon sx={{ color: '#FFFFFF', marginRight: '10px', fontSize: { xs: '1.5rem', md: '2rem' } }} />
                <Typography variant="body1" sx={{ fontSize: { xs: '0.9rem', md: '1.25rem' } }}>
                  Email: <span style={{ color: '#FFFFFF' }}>growaitech@gmail.com</span>
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                <PhoneIcon sx={{ color: '#FFFFFF', marginRight: '10px', fontSize: { xs: '1.5rem', md: '2rem' } }} />
                <Typography variant="body1" sx={{ fontSize: { xs: '0.9rem', md: '1.25rem' } }}>
                  Phone: <span style={{ color: '#FFFFFF' }}>+91 6374549599</span>
                  <br />
                  <span style={{ color: '#FFFFFF' }}>+91 733 921 7119</span>
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                <LocationOnIcon sx={{ color: '#FFFFFF', marginRight: '10px', fontSize: { xs: '1.5rem', md: '2rem' } }} />
                <Typography variant="body2" sx={{ fontSize: { xs: '0.8rem', md: '1.1rem' }, textAlign: 'justify' }}>
                  Door No. 61-4, S.F. No: 25/12 A 2B,
                  <br />
                  Salem Bangalore Byepass Road,
                  <br />
                  Service Rd, Nedunchalai Nagar,
                  <br />
                  Salem, Tamil Nadu 636005
                </Typography>
              </Box>
            </Box>
            {/* Social Media Icons */}
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', marginTop: '20px' }}>
              <IconButton href="https://www.linkedin.com/company/growaitech" target="_blank" rel="noopener noreferrer">
                <LinkedInIcon sx={{ color: '#FFFFFF', '&:hover': { color: '#1A9E7F' } }} />
              </IconButton>
              <IconButton href="https://x.com/GrowAiTech" target="_blank" rel="noopener noreferrer">
                <TwitterIcon sx={{ color: '#FFFFFF', '&:hover': { color: '#1A9E7F' } }} />
              </IconButton>
              <IconButton href="https://github.com/growaitech" target="_blank" rel="noopener noreferrer">
                <GitHubIcon sx={{ color: '#FFFFFF', '&:hover': { color: '#1A9E7F' } }} />
              </IconButton>
              <IconButton href="https://www.instagram.com/growaitech" target="_blank" rel="noopener noreferrer">
                <InstagramIcon sx={{ color: '#FFFFFF', '&:hover': { color: '#1A9E7F' } }} />
              </IconButton>
              <IconButton href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                <FacebookIcon sx={{ color: '#FFFFFF', '&:hover': { color: '#1A9E7F' } }} />
              </IconButton>
            </Box>
          </Box>
        </Grid>

        {/* Second Column */}
        <Grid item xs={12} md={6}>
          <Box sx={{ padding: { xs: '40px', md: '20px' } }}>
            <Typography variant="h5" sx={{ fontWeight: 600, fontSize: { xs: '1.5rem', md: '1.75rem' } }}>
              For Inquiry
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <TextField
                fullWidth
                label="First Name"
                variant="outlined"
                {...register('firstName', { required: 'First name is required' })}
                sx={{ marginTop: '10px' }}
                error={!!errors.firstName}
                helperText={errors.firstName?.message}
              />
              <TextField
                fullWidth
                label="Last Name"
                variant="outlined"
                {...register('lastName', { required: 'Last name is required' })}
                sx={{ marginTop: '10px' }}
                error={!!errors.lastName}
                helperText={errors.lastName?.message}
              />
              <TextField
                fullWidth
                label="Email Address"
                variant="outlined"
                {...register('email', {
                  required: 'Email is required',
                  pattern: {
                    value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                    message: 'Invalid email address',
                  },
                })}
                sx={{ marginTop: '10px' }}
                error={!!errors.email}
                helperText={errors.email?.message}
              />
              <TextField
                fullWidth
                label="Phone Number"
                variant="outlined"
                {...register('phone', { required: 'Phone number is required' })}
                sx={{ marginTop: '10px' }}
                error={!!errors.phone}
                helperText={errors.phone?.message}
              />
              <TextField
                fullWidth
                label="Your Message"
                multiline
                rows={4}
                variant="outlined"
                {...register('message', { required: 'Message is required' })}
                sx={{ marginTop: '10px' }}
                error={!!errors.message}
                helperText={errors.message?.message}
              />
              <Button
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: '#034522',
                  color: '#FFFFFF',
                  marginTop: '20px',
                  '&:hover': {
                    backgroundColor: '#1A9E7F',
                  },
                }}
              >
                Send Message
              </Button>
            </form>
          </Box>
        </Grid>
      </Grid>

      {/* Snackbar for success message */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        message={
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <CheckCircleIcon sx={{ color: 'green', marginRight: '8px' }} />
            Message sent successfully!
          </Box>
        }
      />
    </Box>
  );
}

export default Contact;
