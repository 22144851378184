import React from 'react';
import { Box, Typography } from '@mui/material';
import aboutimage from "../images/about1.png"; // Ensure this path is correct

function About() {
  return (
    <Box>
      {/* About Us Header */}
      <Box>
        <Typography
          variant="h4"
          sx={{
            fontFamily: 'Lato',
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: { xs: '24px', sm: '30px', md: '35px' }, // Responsive font sizes
            lineHeight: '28px',
            paddingLeft: { xs: '0px', sm: '20px', md: '40px' }, // Center on mobile, adjust padding for larger screens
            paddingRight: { xs: '0px', sm: '20px', md: '40px' }, // Center on mobile, adjust padding for larger screens
            textAlign: { xs: 'center', sm: 'left' }, // Center text on mobile
            color: '#034522',
          }}
        >
          About Us
        </Typography>

      </Box>

      {/* "Crafting Code, Creating Impact" */}
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        <Typography
          variant="h5"
          sx={{
            fontFamily: 'Lato',
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: { xs: '20px', sm: '25px', md: '30px' }, // Responsive font size
            lineHeight: { xs: '30px', md: '50px' }, // Adjust line height for smaller screens
            color: '#1A9E7F',
            textAlign: 'center',
          }}
        >
          "Crafting Code, Creating Impact"
        </Typography>
      </Box>

      {/* Company Description */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '20px',
          paddingX: { xs: '15px', sm: '30px', md: '0px' }, // Padding adjustments for mobile
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Lato',
            fontStyle: 'normal',
           
            fontSize: { xs: '18px', sm: '22px', md: '25px' }, // Responsive font sizes
            lineHeight: { xs: '30px', md: '50px' }, // Adjust line height for mobile
            color: '#000000',
            textAlign: 'center',
          }}
        >
          At GrowAITech, our team of coding professionals is dedicated to providing cutting-edge IT solutions
          tailored to your business needs. With a focus on Web Development, Artificial Intelligence, Data
          analytics, Cybersecurity, and creative design, we are committed to delivering high-quality, scalable
          software solutions. Our deep industry knowledge and technical expertise allow us to transform complex
          challenges into efficient, robust systems that drive your success.
        </Typography>
      </Box>

      {/* Flex Container for Image and Text */}
      <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" justifyContent="center" marginTop="30px">

        {/* Empowering Statement */}
        <Box sx={{ flex: 1, marginRight: { md: '20px' }, marginBottom: { xs: '20px', md: '0px' }, textAlign: { xs: 'center', md: 'center' } }}>
          <Typography
            variant="h5"
            sx={{
              fontFamily: 'Lato',
              fontStyle: 'normal',
              fontWeight: 700,
              fontSize: { xs: '20px', sm: '25px', md: '30px' }, // Responsive font sizes
              lineHeight: { xs: '30px', md: '50px' }, // Adjust line height for mobile
              color: '#1A9E7F',
            }}
          >
            "Empowering businesses with technology,
            <br />
            one line of code at a time."
          </Typography>
        </Box>

        {/* Image Section with Dynamic Height */}
        <Box
          component="img"
          sx={{
            flex: 1,
            width: { xs: '100%', sm: '500px', md: '682px' }, // Adjust image width for small screens
            objectFit: 'contain', // Ensures the image scales correctly
          }}
          alt="Company Image"
          src={aboutimage} // Path to your image file
        />
      </Box>
    </Box>
  );
}

export default About;
