import React from 'react';
import { Box, Typography } from '@mui/material';

function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: '#034522',
        color: 'white',
        padding: '16px',
        textAlign: 'center',
        marginTop: 'auto',
      }}
    >
      <Typography variant="body2">© 2024 Growaitech. All Rights Reserved.</Typography>
    </Box>
  );
}

export default Footer;
