import React, { useState } from 'react';
import { Box, Typography, Grid, Card, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';

// Import images
import webImage from '../images/web.png';
import aiImage from '../images/AI.png';
import dataAnalyticsImage from '../images/DA.png';
import cybersecurityImage from '../images/CS.png';
import creativeDesignImage from '../images/design.png';
import appDevelopmentImage from '../images/AD.png';
import internshipImage from '../images/internship.png';
import workshopImage from '../images/workshop.png';

const servicesData = [
  {
    title: 'Web Development',
    image: webImage,
    details: 'Web development involves building and maintaining websites. It encompasses various aspects including web design, web publishing, web programming, and database management. In our courses, you will learn about HTML, CSS, JavaScript, and frameworks like React and Node.js to create interactive and responsive websites.',
  },
  {
    title: 'Artificial Intelligence',
    image: aiImage,
    details: 'Artificial Intelligence (AI) is the simulation of human intelligence in machines that are programmed to think like humans and mimic their actions. AI is applied in various sectors like healthcare, finance, and automotive. Our program covers machine learning, neural networks, and deep learning technologies to equip you with in-demand skills.',
  },
  {
    title: 'Data Analytics',
    image: dataAnalyticsImage,
    details: 'Data analytics is the process of examining data sets to draw conclusions about the information they contain. It involves statistical analysis and the use of specialized software to interpret data. Our training includes data visualization, predictive analytics, and tools such as Excel, R, and Python for effective data analysis.',
  },
  {
    title: 'Cybersecurity',
    image: cybersecurityImage,
    details: 'Cybersecurity involves protecting systems, networks, and programs from digital attacks. These cyberattacks are usually aimed at accessing, changing, or destroying sensitive information. Our courses teach you about threat modeling, risk assessment, and security protocols to help safeguard sensitive data.',
  },
  {
    title: 'Creative Design',
    image: creativeDesignImage,
    details: 'Creative design is the art of generating ideas and translating them into visual content. It encompasses graphic design, user interface design, and user experience design. Our program helps you develop a keen eye for design, mastery of design tools, and the ability to create stunning visuals that captivate users.',
  },
  {
    title: 'App Development',
    image: appDevelopmentImage,
    details: 'App development is the process of creating software applications for mobile devices. It involves designing and building applications that run on platforms like iOS and Android. In our courses, you will learn about native and hybrid app development, UI/UX design, and backend integration.',
  },
];

function Services() {
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedService, setSelectedService] = useState(null);

  const handleApplyNow = () => {
    window.open('https://forms.gle/VWbfxxMatLwjXJxo9', '_blank');
  };

  const handleEnrollNow = () => {
    window.open('https://forms.gle/y23rpEYpSW1EnTye7', '_blank');
  };

  const handleCardClick = (service) => {
    setSelectedService(service);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedService(null);
  };

  return (
    <Box sx={{ padding: '20px' }}>
      <Typography
        variant="h4"
        color="primary"
        sx={{
          fontFamily: 'Lato, sans-serif',
          fontStyle: 'normal',
          fontWeight: 700,
          fontSize: {
            xs: '30px',   // Font size for extra-small screens
            sm: '40px',   // Font size for small screens
            md: '50px',   // Font size for medium screens
            lg: '55px',   // Font size for large screens
          },
          lineHeight: {
            xs: '35px',   // Line height for extra-small screens
            sm: '45px',   // Line height for small screens
            md: '55px',   // Line height for medium screens
            lg: '60px',   // Line height for large screens
          },
          textAlign: 'center',
          color: '#034522',
        }}
      >
        Our Services
      </Typography>

      <Typography
        sx={{
          fontFamily: 'Lato',
          fontStyle: 'normal',
          fontWeight: 700,
          fontSize: { xs: '18px', sm: '22px', md: '25px' },
          lineHeight: { xs: '30px', md: '50px' },
          color: '#000000',
          textAlign: 'center',
          marginTop: '10px',
        }}
      >
        We offer a wide range of IT services, including:
      </Typography>

      <Grid container spacing={3} sx={{ marginTop: '20px' }}>
        {servicesData.map((service, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              onClick={() => handleCardClick(service)}
              sx={{
                position: 'relative',
                width: { xs: '100%', sm: '300px', md: '280px', lg: '350px' },
                height: '380px',
                borderRadius: '8px',
                overflow: 'hidden',
                border: '5px solid #FFFFFF',
                backgroundColor: '#FFFFFF',
                boxShadow: 3,
                margin: { xs: '0 auto', sm: 'unset' },
                transition: 'transform 0.3s',
                '&:hover': {
                  transform: 'scale(1.05)', // Scale effect on hover
                },
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  height: '368px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'absolute',
                  top: 0,
                }}
              >
                <Box
                  component="img"
                  src={service.image}
                  alt={service.title}
                  sx={{
                    maxWidth: '100%',
                    maxHeight: '100%',
                    objectFit: 'cover',
                    borderRadius: '8px 8px 0px 0px',
                  }}
                />
              </Box>
              <Box
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  width: '100%',
                  height: '77px',
                  background: '#034522',
                  border: '5px solid #FFFFFF',
                  borderRadius: '0px 0px 8px 8px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography
                  variant="h6"
                  component="div"
                  sx={{
                    color: '#FFFFFF',
                    fontFamily: 'Lato',
                    fontWeight: 700,
                    fontSize: { xs: '20px', sm: '25px', md: '22px', lg: '30px' },
                    lineHeight: '50px',
                    textAlign: 'center',
                  }}
                >
                  {service.title}
                </Typography>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Internship Program Section */}
      <Grid container spacing={3} sx={{ marginTop: '50px', alignItems: 'center' }}>
        <Grid item xs={12}>
          <Typography
            sx={{
              fontFamily: 'Lato',
              fontStyle: 'normal',
              fontWeight: 700,
              fontSize: { xs: '30px', sm: '40px', md: '55px' },
              lineHeight: '50px',
              textAlign: 'center',
              color: '#034522',
            }}
          >
            Gain Real-World Experience with Our Internship Program
          </Typography>
        </Grid>
        {/* Left Side - Text and Button */}
        <Grid item xs={12} sm={6} sx={{ textAlign: 'center', position: 'relative' }}>
          <Typography
            sx={{
              fontFamily: 'Lato',
              fontStyle: 'normal',
              
              fontSize: '20px',
              lineHeight: '30px',
              color: '#000000',
              textAlign: 'center',
              marginTop: '20px',
            }}
          >

            "We offer exciting internship opportunities lasting from 10 days to 6 months. Whether you're a student or an aspiring professional, you can gain practical experience in various fields, including web development, AI, data analytics, cybersecurity, and creative design. Our experienced team is dedicated to mentoring you throughout your journey. You'll develop essential skills that will help you thrive in your career. Embrace the chance to work on real projects and learn from industry experts. Start your path to success with us!"
          </Typography>
          <Button
            variant="contained"
            onClick={handleApplyNow} // Call the function here
            sx={{
              backgroundColor: '#034522',
              color: '#FFFFFF',
              fontWeight: 700,
              fontSize: '20px',
              padding: '10px 20px',
              marginTop: '20px',
              '&:hover': {
                backgroundColor: '#026a1f',
              },
            }}
          >
            Apply Now
          </Button>
        </Grid>

        {/* Right Side - Image */}
        <Grid item xs={12} sm={6} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box
            component="img"
            src={internshipImage}
            alt="Internship Program"
            sx={{
              width: '300px',  // Set width to 300px
              height: '350px', // Set height to 250px
              borderRadius: '8px',
              boxShadow: 3,
            }}
          />
        </Grid>
      </Grid>

      {/* Workshop Section */}
      <Grid container spacing={3} sx={{ marginTop: '50px', alignItems: 'center' }}>
        <Grid item xs={12}>
          <Typography
            sx={{
              fontFamily: 'Lato',
              fontStyle: 'normal',
              fontWeight: 700,
              fontSize: { xs: '30px', sm: '40px', md: '55px' },
              lineHeight: '50px',
              textAlign: 'center',
              color: '#034522',
            }}
          >
            Join Our Interactive Workshops
          </Typography>
        </Grid>
        {/* Left Side - Image */}
        <Grid item xs={12} sm={6} sx={{ display: 'flex', justifyContent: 'center' }}>

          <Box
            component="img"
            src={workshopImage}
            alt="Internship Program"
            sx={{
              width: '300px',  // Set width to 300px
              height: '350px', // Set height to 250px
              borderRadius: '8px',
              boxShadow: 3,
            }}
          />
        </Grid>

        {/* Right Side - Text and Button */}
        <Grid item xs={12} sm={6} sx={{ textAlign: 'center', position: 'relative' }}>
          <Typography
            sx={{
              fontFamily: 'Lato',
              fontStyle: 'normal',
              
              fontSize: '20px',
              lineHeight: '30px',
              color: '#000000',
              textAlign: 'center',
              marginTop: '20px',
            }}
          >
            "Our workshops offer hands-on training in the latest technologies, allowing participants to engage in collaborative projects that enhance their skills. Led by industry experts, these sessions provide invaluable insights and resources that are essential for success in today's job market. Attendees will work on real-world scenarios, fostering teamwork and problem-solving abilities. Networking opportunities will also be available, enabling connections with peers and professionals. Join us to elevate your skills and stay ahead in the ever-evolving tech landscape. Don't miss out on the chance to learn from the best!"
          </Typography>
          <Button
            variant="contained"
            onClick={handleEnrollNow} // Call the function here
            sx={{
              backgroundColor: '#034522',
              color: '#FFFFFF',
              fontWeight: 700,
              fontSize: '20px',
              padding: '10px 20px',
              marginTop: '20px',
              '&:hover': {
                backgroundColor: '#026a1f',
              },
            }}
          >
            Enroll Now
          </Button>
        </Grid>
      </Grid>

      {/* Dialog for Service Details */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{selectedService?.title}</DialogTitle>
        <DialogContent>
          <Typography>{selectedService?.details}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default Services;
