import React, { useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { IconButton, Box } from '@mui/material';
import { FaRobot } from 'react-icons/fa';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import Contact from './pages/Contact';
import Chatbox from './components/Chatbox'; // Import your Chatbox component
import './App.css';

// Custom theme with typography settings
const theme = createTheme({
  palette: {
    primary: {
      main: '#034522',
    },
    secondary: {
      main: '#1A9E7F',
    },
  },
  
});

function App() {
  const [showChatbox, setShowChatbox] = useState(false);

  const toggleChatbox = () => {
    setShowChatbox((prev) => !prev);
  };

  return (
    <ThemeProvider theme={theme}>
      <Header />
      <div id="home">
        <Home />
      </div>
      <div id="about">
        <About />
      </div>
      <div id="services">
        <Services />
      </div>
      <div id="contact">
        <Contact />
      </div>
      <Footer />

      {/* Floating Robot Icon */}
      <IconButton
        onClick={toggleChatbox}
        sx={{
          position: 'fixed',
          bottom: 16,
          right: 16,
          backgroundColor: 'secondary.main',
          color: 'white',
          '&:hover': { backgroundColor: 'primary.main' },
        }}
      >
        <FaRobot size={40} />
      </IconButton>

      {/* Background Blur Effect */}
      {showChatbox && <div className="blur-background"></div>}

      {/* Chatbox Component */}
      {showChatbox && (
        <Box
          sx={{
            position: 'fixed',
            bottom: 80,
            right: 16,
            width: '100%',
            maxWidth: '400px',
            zIndex: 1000,
          }}
        >
          <Chatbox onClose={toggleChatbox} /> {/* Pass onClose prop */}
        </Box>
      )}
    </ThemeProvider>
  );
}

export default App;
