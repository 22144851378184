import React, { useEffect, useState } from 'react';
import { Box, Card, Typography } from '@mui/material';
import backgroundImage from '../images/background-image.png'; // Adjust path according to your structure
import cardImage1 from '../images/card1.png'; // Import image
import cardImage2 from '../images/card2.png'; // Import images
import cardImage3 from '../images/card3.png'; // Import images
import cardImage4 from '../images/card4.png'; // Import images
import cardImage5 from '../images/card5.png'; // Import images

// Sample JSON data for 5 cards
const data = [
  {
    text: {
      tagline: "Empowering Coders, Innovating Solutions",
      title: "Empowering Digital Transformation Through Innovation",
      description: "Our team of highly skilled professionals leverages the latest technologies to deliver innovative and efficient solutions, ensuring your business operates smoothly and successfully.",
    },
    image: cardImage1, // Use imported image
  },
  {
    text: {
      tagline: "Innovate with Confidence",
      title: "Driving Change Through Technology",
      description: "We empower businesses to embrace digital transformation and thrive in the modern world.",
    },
    image: cardImage2, // Use imported image
  },
  {
    text: {
      tagline: "Solutions that Matter",
      title: "Transforming Ideas into Reality",
      description: "Our solutions are designed to meet the unique needs of each client, fostering innovation and growth.",
    },
    image: cardImage3, // Use imported image
  },
  {
    text: {
      tagline: "Your Vision, Our Mission",
      title: "Custom Solutions for Unique Challenges",
      description: "Partner with us to turn your ideas into successful projects with tailored solutions.",
    },
    image: cardImage4, // Use imported image
  },
  {
    text: {
      tagline: "Future-Proof Your Business",
      title: "Adapting to a Digital World",
      description: "We help businesses stay ahead by providing solutions that adapt to changing technology landscapes.",
    },
    image: cardImage5, // Use imported image
  },
];

function Home() {
    const [currentIndex, setCurrentIndex] = useState(0); // State to track the current card index

    useEffect(() => {
        // Set interval to change the current index every 5 seconds
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % data.length); // Loop back to the first card
        }, 5000); // Change every 5 seconds

        return () => clearInterval(interval); // Cleanup the interval on component unmount
    }, []);

    const currentData = data[currentIndex]; // Get current data based on index

    return (
        <Box
            sx={{
                height: {
                    xs: '100vh', // Adjust height for mobile view
                     // Full height for larger screens
                },
                backgroundImage: `url(${backgroundImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Card
                sx={{
                    width: {
                        xs: '90%',
                        sm: '80%',
                        md: '75%',
                        lg: '70%',
                        xl: '60%',
                    },
                    height: {
                        xs: 'auto',
                        sm: '400px',
                        md: '450px',
                        lg: '500px',
                        xl: '550px',
                    },
                    maxWidth: '1222px',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    flexDirection: {
                        xs: 'column',
                        sm: 'row',
                    },
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: 2,
                    borderRadius: 2,
                }}
            >
                <Box
                    sx={{
                        flex: 1,
                        padding: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        textAlign: 'center',
                    }}
                >
                    <Typography
                        variant="body2"
                        sx={{
                            backgroundColor: '#FF9A15',
                            letterSpacing: '0.3em',
                            padding: 1,
                            borderRadius: '4px',
                            textTransform: 'uppercase',
                            fontSize: {
                                xs: '0.5rem',
                                sm: '0.7rem',
                                md: '0.7rem',
                                lg: '1rem',
                                xl: '1rem',
                            },
                        }}
                    >
                        {currentData.text.tagline}
                    </Typography>

                    <Typography
                        variant="h2"
                        component="div"
                        color="#fff"
                        textAlign="center"
                        sx={{
                            marginTop: 1,
                            fontSize: {
                                xs: '1.2rem',
                                sm: '1.5rem',
                                md: '1.8rem',
                                lg: '2.2rem',
                                xl: '2.4rem',
                            },
                        }}
                    >
                        {currentData.text.title}
                    </Typography>

                    <Typography
                        variant="body1"
                        color="#fff"
                        textAlign="center"
                        sx={{
                            marginTop: 1,
                            fontSize: {
                                xs: '0.7rem',
                                sm: '0.9rem',
                                md: '1rem',
                                lg: '1.1rem',
                                xl: '1.2rem',
                            },
                        }}
                    >
                        {currentData.text.description}
                    </Typography>
                </Box>

                <Box
                    sx={{
                        flex: 1,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: 2,
                    }}
                >
                    <img
                        src={currentData.image} // Get current image based on index
                        alt="Example"
                        style={{
                            width: '80%',
                            height: 'auto',
                            objectFit: 'cover',
                        }}
                    />
                </Box>
            </Card>
        </Box>
    );
}

export default Home;

